<template>
  <div style="max-width: 400px">
    <div class="neo-card">
      <v-form ref="verifyForm" v-model="valid" lazy-validation>
        <h2 class="heading-size-4 mb-4">{{ $t("promptPhone") }}</h2>
        <div class="neo-text-field">
          <div>{{ $t("phoneNumber") }}</div>

          <vue-tel-input-vuetify
            :disabled="!!this.$store.state.user.phoneNumber"
            autoformat
            required
            flat
            solo
            outlined
            dense
            disabledFetchingCountry
            v-model="telephoneInputS"
            :error="phoneError"
            :preferredCountries="['JP']"
            @input="onTelephoneInput"
            :dropdownOptions="{
              showFlags: false,
              showDialCode: false,
            }"
            :inputOptions="{
              showFlags: false,
              showDialCodeInSelection: true,
            }"
            select-label="code"
            append-icon="mdi-menu-down"
            :label="$t('phoneNumber')"
          ></vue-tel-input-vuetify>
        </div>
      </v-form>
      <div>{{ userMessage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetPhone",
  props: {
    phoneNumber: String,
  },
  data() {
    return {
      userMessage: " ",
      telephoneInputS: "",
      telephoneInput: {
        number: "",
        valid: false,
        country: "JP",
      },
      validPhone: false,
      phoneError: false,
      manualRecaptcha: undefined,
      waiting: false,
      recaptchaOk: false,
      valid: true,
      rules: {
        error: () => true || "",
        required: (value) => !!value || "Required.",
      },
      disableGetCode: false,
      confirmationResult: undefined,
    };
  },
  watch: {
    phoneNumber(newVal) {
      this.telephoneInputS = newVal;
      if (this.$store.state.user.phoneNumber) this.userMessage = this.$t("phoneVerified");
    },
  },
  methods: {
    telValidate(telnumber) {
      this.validatedphonenumber = telnumber.number;
      this.validphonenumber = telnumber.valid;
      return this.validphonenumber;
    },
    onTelephoneInput(formattedNumber, telephone) {
      if (!telephone) return;

      const { number, valid, country } = telephone;
      this.validPhone = valid;
      if (valid) {
        this.phoneError = false;
        this.telephone = {
          number: number.e164,
          country: country.iso2,
          value: number.input,
        };
        this.$emit("validPhone", this.telephone);
        if (!this.$store.state.user.phoneNumber) this.userMessage = " ";
      } else {
        this.$emit("invalidPhone");
        this.userMessage = this.$t("invalidPhone");
      }
    },
    clearError() {
      this.errorMsgPwd = "";
      this.errorMsgEmail = "";
      this.rules.error = true;
    },
    handleEnter() {
      this.$refs.sendBtn.$el.click();
    },
  },
  mounted() {
    this.telephoneInputS = this.phoneNumber;
    if (!this.telephoneInputS) this.userMessage = this.$t("promptPhone");
    if (this.$store.state.user.phoneNumber) {
      this.userMessage = this.$t("phoneVerified");
    }
    
  },
};
</script>
